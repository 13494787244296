import { getInfo } from "../helpers"
import state from "./state"

// initial state
const initialState: state = {

    // base
    theme: getInfo("user", "settings")?.theme ? getInfo("user", "settings")?.theme : "auto",
    themeError: "",
    unMount: undefined,
    loading: false,
    authenticated: false,
    disabled: false,
    all: true,
    id: "",
    ids: [],
    edit: false,
    notification: "",
    searchKeyword: "",
    schema: "all",
    collection: "all",
    backendStatus: "restored",
    files: [],
    file: null,
    filesError: "",
    selected: [],
    propsCondition: {},
    description: "",
    descriptionError: "",
    totalAmount: "",
    totalAmountError: "",
    paidAmount: "",
    paidAmountError: "",
    date: "",
    dateError: "",
    location: "",
    locationError: "",
    tin: "",
    tinError: "",
    status: "cash",
    statusError: "",
    orderNumber: 0,
    type: "",
    typeError: "",

    // user
    account: "",
    accountError: "",
    password: "",
    passwordError: "",
    oldPassword: "",
    oldPasswordError: "",
    code: "",
    codeError: "",
    passwordConfirmation: "",
    passwordConfirmationError: "",
    userName: "",
    userNameError: "",
    phoneNumber: "",
    phoneNumberError: "",
    extraPhoneNumber: "",
    extraPhoneNumberError: "",
    fullName: "",
    fullNameError: "",
    accountType: "",
    accountTypeError: "",
    twoFactorAuthenticationEnabled: "true",
    users: [],
    user: null,
    oldPhoneNumber: "",
    phoneNumberVerified: false,
    apiKey: "",
    apiKeyError: "",
    onlineUsers: [],

    // listing
    page: 1,
    pages: 0,
    nextPage: 0,
    previousPage: 0,
    limit: 100,
    sort: "createdAt",
    order: 1,
    condition: "",
    limits: [],
    pageNumbers: [],
    list: [],
    select: {},
    joinForeignKeys: false,
    fields: [],


    // product
    productName: "",
    productNameError: "",
    buyingPrice: "",
    buyingPriceError: "",
    sellingPrice: "",
    sellingPriceError: "",
    stock: "",
    stockError: "",
    quantity: "",
    quantityError: "",
    reorderStockLevel: "",
    reorderStockLevelError: "",
    products: [],
    product: null,
    productId: "",

    // activity
    activities: [],
    activity: null,

    // branch
    branches: [],
    branch: null,

    // customer
    customers: [],
    customer: null,
    customerName: "",
    customerNameError: "",
    customerId: "",

    //debt
    debts: [],
    debt: null,
    debt_histories: [],
    debt_history: null,
    debtType: "",
    debtTypeError: "",

    // device
    devices: [],
    device: null,

    // expense
    expenses: [],
    expense: null,
    expense_types: [],
    expense_type: null,
    expense_typeName: "",
    expense_typeNameError: "",
    expenseName: "",
    expenseNameError: "",

    // order
    orders: [],
    // order: null,

    // payment
    payments: [],
    payment: null,

    // purchase
    purchases: [],
    purchase: null,

    // role
    roles: [],
    role: null,

    // sales
    sales: [],
    sale: null,
    pos: "cart",

    // service
    services: [],
    service: null,

    // store
    stores: [],
    store: null,
    store_products: [],
    store_product: null,

    name: "",
    nameError: "",
    region: "",
    regionError: "",
    street: "",
    streetError: "",
    vendor: "",
    vendorError: "",
    fee: "",
    feeError: "",
    image: "",
    imageError: "",
    oldImage: "",
    oldImageError: "",
    permissions: [],
    permissionsError: "",
    message: null,
    report: null,
    proforma_invoice: null,
    branchId: "",
    branchIdError: "",
    roleId: "",
    roleIdError: "",
    paymentType: "",
    paymentTypeError: "",
    messages: [],
    receivers: "",
    receiversError: "",
    costPerMessage: 40,
    messageCost: 0,
    branchName: "",
    branchNameError: "",
    title: "",
    storeId: "",
    storeIdError: "",
    store_product_id: "",
    pathname: "",
    adjustment: "",
    adjustmentError: "",
    adjustments: [],
    balance: "",
    balanceError: "",
    reportType: "unMount",
    reportTypeError: "",
    viewDeletedReport: "no",
    dateTo: "",
    dateToError: "",
    customerIdError: "",
    expense_typeId: "",
    expense_typeIdError: "",
    storeProductId: "",
    storeProductIdError: "",
    productIdError: "",
    userId: "",
    userIdError: "",
    year: new Date().getFullYear(),
    yearError: "",
    notifications: [],
    saleNote: "",
    saleNoteError: "",
    invoiceNote: "",
    invoiceNoteError: "",
    closingTime: "",
    closingTimeError: "",
    openingTime: "",
    openingTimeError: "",
    paymentMethods: [],
    email: "",
    emailError: "",
    website: "",
    websiteError: "",
    pobox: "",
    poboxError: "",
    language: "english",
    languageError: "",
    systemLoading: "yes",
    systemLoadingError: "",
    customer_debt: null,
    shop_debt: null,
    currencyFormat: "0,0",
    currencyFormatError: "",
    cashSales: 0,
    creditSales: 0,
    customerPaidDebts: 0,
    customerPartialPaidDebts: 0,
    customerUnpaidDebts: 0,
    revenue: 0,
    purchaseCost: 0,
    freight: 0,
    cogs: 0,
    grossProfit: 0,
    totalPayments: 0,
    paidExpenses: 0,
    partialPaidExpenses: 0,
    unpaidExpenses: 0,
    shopPaidDebts: 0,
    shopPartialPaidDebts: 0,
    shopUnpaidDebts: 0,
    totalExpenses: 0,
    netIncome: 0,
    supplier: null,
    supplierName: "",
    supplierNameError: "",
    suppliers: [],
    supplierId: "",
    supplierIdError: "",
    invoiceNumber: "",
    invoiceNumberError: "",
    customer_count: null,
    customer_counts: [],
    route: null,
    routes: [],
    routeId: "",
    routeIdError: "",
    truck: null,
    trucks: [],
    truckId: "",
    truckIdError: "",
    truck_order: null,
    truck_orders: [],
    truckOrderId: "",
    truckOrderIdError: "",
    from: "",
    fromError: "",
    to: "",
    toError: "",
    distance: "",
    distanceError: "",
    cost: "",
    costError: "",
    plateNumber: "",
    plateNumberError: "",
    statusBefore: "",
    statusBeforeError: "",
    statusAfter: "",
    statusAfterError: "",
    paidOrders: 0,
    unpaidOrders: 0,
    partialPaidOrders: 0,
    barcode: "",
    barcodeError: "",
    cargo: "",
    cargos: [],
    cargoId: "",
    cargoIdError: "",
    paidCargos: 0,
    unpaidCargos: 0,
    partialPaidCargos: 0,
    offset: 0,
    visibleData: [],
    stocks: [],
    days: "",
    daysError: "",
    deviceName: "",
    deviceNameError: "",
    deviceId: "",
    deviceIdError: "",
    brand: "",
    brandError: "",
    model: "",
    modelError: "",
    imei: "",
    imeiError: "",
    key: "",
    keyError: "",
    value: "",
    valueError: "",
    features: [],
    serviceName: "",
    serviceNameError: "",
    serviceCost: "",
    serviceCostError: "",
    productCost: "",
    productCostError: "",
    completedServices: 0,
    incompleteServices: 0,
    freightName: "",
    freightNameError: "",
    freights: [],
    paidFreights: 0,
    unpaidFreights: 0,
    partialPaidFreights: 0,
    darkColor: "#1B1B1B",
    lightColor: "#F0F0F9",
    storeName: "",
    storeNameError: "",
    routeName: "",
    routeNameError: "",
    truckName: "",
    truckNameError: "",
    roleName: "",
    roleNameError: "",
    accountNumber: "",
    accountNumberError: "",
    provider: "",
    providerError: "",
    accounts: [],
    reference: "",
    referenceError: "",
    accountData: null,
    transaction: null,
    transactions: [],
    secondAccount: "",
    secondAccountError: "",
    secondAccounts: [],
    secondAccountData: null,
    useSupplierAccount: "no",
    useCustomerAccount: "no",
    isStoreProduct: false,
    hotelName: "",
    hotelNameError: "",
    hotelCategory: "",
    hotelCategoryError: "",
    hotelRooms: [],
    hotelRoomPrice: "",
    hotelRoomPriceError: "",
    hotelRoomType: "",
    hotelRoomTypeError: "",
    hotel: null,
    hotels: [],
    hotelId: "",
    attractionId: "",
    attractionName: "",
    attractionNameError: "",
    attractionCategory: "",
    attractionCategoryError: "",
    attraction: null,
    attractions: [],
    childFee: "",
    childFeeError: "",
    driverAndCarFee: "",
    driverAndCarFeeError: "",
    price: "",
    priceError: "",
    isMandatory: "no",
    item: null,
    items: [],
    attraction_activity: null,
    attraction_activities: [],
    attractionActivityName: "",
    attractionActivityNameError: "",
    margin: "",
    marginError: "",
    adultPopulation: "",
    adultPopulationError: "",
    adultPopulationDays: "",
    adultPopulationDaysError: "",
    adultPopulationTotal: "",
    adultPopulationTotalError: "",
    childPopulation: "",
    childPopulationError: "",
    childPopulationDays: "",
    childPopulationDaysError: "",
    childPopulationTotal: "",
    childPopulationTotalError: "",
    driverPopulation: "",
    driverPopulationError: "",
    driverPopulationDays: "",
    driverPopulationDaysError: "",
    driverPopulationTotal: "",
    driverPopulationTotalError: "",
    admissionTotal: "",
    accomodationTotal: "",
    itemCount: "",
    itemDays: "",
    itemTotal: "",
    numberOfPeople: "",
    numberOfPeopleError: "",
    childCostPercentage: "",
    childCostPercentageError: "",
    deposit: "",
    depositError: "",
    totalMargin: "",
    quotations: [],
    quotation: "",
    trips: [],
    quotationNumber: "",
    quotationNumberError: "",
    quotationId: "",
    quotation_invoices: [],
    quotation_invoice: null,
    country: "tanzania",
    countryError: "",
    phoneCode: "255",
    phoneCodeError: "",
    identificationNumber: "",
    identificationNumberError: "",
    vendors: [],
    paidInvoice: 0,
    unpaidInvoice: 0,
    discount: 0,
    hasReceipt: "no",
    eastAfricaPrice: "",
    eastAfricaPriceError: "",
    nonResidentPrice: "",
    nonResidentPriceError: "",
    expatriatePrice: "",
    expatriatePriceError: "",
    category: null,
    categories: [],
    categoryName: "",
    categoryNameError: "",
    categoryId: "",
    season: "",
    seasonError: "",
    profit: "",
    profitError: "",
    primaryColor: "#0066CC",
    primaryColorError: "",
    fontFamily: "Google Sans",
    fontFamilyError: "",
    cif: "",
    cifError: "",
    priceInTZS: "",
    priceInTZSError: "",
    position: "",
    positionError: "",
    oldStock: 0,
    isPos: window.location.pathname.includes("pos"),
    barcodeValue: "",
    request: null,
    requests: [],
    saleLimit: "",
    saleLimitError: "",
    purchaseLimit: "",
    purchaseLimitError: "",
    productCode: "",
    productCodeError: "",
    debtLimit: "",
    debtLimitError: "",
    loadTRAReceipt: "no",
    traItemName: "",
    traItemNameError: "",
}

// exporting initial state
export default initialState